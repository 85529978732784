export default class DelayedAction {
	constructor(condition, action, delay=1000, {initialDelay=false}={}) {
		this.condition = condition;
		this.action = action;
		this.delay = delay;

		this.stopped = false;
		this.performed = false;
		this.nextCheckId = null;

		if (initialDelay) {
			this.scheduleNextCheck();
		} else {
			this.checkCondition();
		}
	}

	checkCondition() {
		if (!this.stopped) {
			Promise.resolve(this.condition()).then(result => {
				if (result) {
					this.performAction();
				} else {
					this.scheduleNextCheck();
				}
			});
		}
	}

	scheduleNextCheck() {
		this.nextCheckId = setTimeout(this.checkCondition.bind(this), this.delay);
	}

	performAction() {
		this.action();
		this.stopCheck();
		this.performed = true;
	}

	stopCheck() {
		clearTimeout(this.nextCheckId);
		this.stopped = true;
	}
}

export default class InitTooltip {
	constructor() {
		if($('[data-toggle="tooltip"]').length > 0) {
			console.warn('change [data-toggle="tooltip"] to [data-tooltip]');
			$('[data-toggle="tooltip"]').tooltip();
		}
		if($('[data-tooltip]').length > 0) {
			$('[data-tooltip]').tooltip({ boundary: 'window' });
		}

		$(document).on("click","[data-tooltip]",event=>{
			$("[data-tooltip]").tooltip("hide");
		})
	}
}

export default class Utils{
	constructor(){
		this.initNoFocus();
    this.initOpacityToggle();
    this.clickOpacityToggle();
    this.keepFocusDropdown();
    this.ToggleBanner();
    this.ScrollTo();
    this.allowTransitions();
	}

  allowTransitions() {
    $("body").removeClass("preload");
  }

  initNoFocus(event){
    $(document).on("focus",".no-focus",event=>{
      let $current = $(event.currentTarget);
      $current.blur();
    })
  }

  initOpacityToggle(event){
    $(document).on("focus",".opacity-toggle",event=>{
      let $current = $(event.currentTarget);
      $current.fadeTo(200,0.5).fadeTo(200,1);
    })
  }

  clickOpacityToggle(event){
    $(document).on("click",".click-toggle",event=>{
      let $current = $(event.currentTarget);
      $current.fadeTo(100,0.7).fadeTo(100,1);
    })
  }

  keepFocusDropdown(event){
    $(document).on("click",".keep-focus",event=>{
      event.stopPropagation();
    })
  }

  // Sticky booking banner
  ToggleBanner(){
    $('.bottom-banner').hide();
    $(document).scroll(function() {
      var y = $(this).scrollTop();

      if (y > 1840) {
        $('.bottom-banner').slideDown("fast");
      } else {
        $('.bottom-banner').slideUp("fast");
      }
    });
  };


  // Animate scroll it the url have anchor
  ScrollTo() {
    $('a[href*="#"]')
    .not('[href="#"]')
    .not('[href="#0"]')

    .click(function(event) {
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
        &&
        location.hostname == this.hostname
      ) {

        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

        if (target.length) {
          event.preventDefault();
          $('html, body').animate({
            scrollTop: target.offset().top - 20
          }, 660, function() {

            var $target = $(target);
            $target.focus();
            if ($target.is(":focus")) {
              return false;
            } else {
              $target.attr('tabindex','-1');
              $target.focus();
            };
          });
        }
      }
    });
  }

}
